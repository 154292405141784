import { useCallback, useEffect, useMemo } from "react";
import Form, { List } from "rc-field-form";
import Button from "../../../Button";
import classNames from "classnames";
import { isEqual } from "lodash";
import Filter from "./Filter";
import Toggle from "./Toggle";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { isFilterValid } from "../../../../utils/api/format";
import { useTranslation } from "react-i18next";
import "./_style.scss";

export const initialFilters = [{ name: "", method: "", value: undefined }];

function Filters({ columns, filters, setFilters, hideFilterButton, setHasChangedDateFilter }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { visible, toggle } = useVisible(true);
  const list = Form.useWatch("filters", form);

  const defaultFilter = useMemo(() => {
    return Array.isArray(filters) && filters.length ? [{ ...filters[0], value: undefined }] : initialFilters;
  }, [filters])

  const hasChange = useMemo(() => {
    return !isEqual(filters, list);
  }, [filters, list]);

  const areValid = useMemo(() => {
    return list?.every(isFilterValid);
  }, [list]);

  const changeFieldMethod = useCallback(
    (name, method) => {
      form.setFieldValue(["filters", name, "method"], method);
    },
    [form]
  );

  const changeFieldValue = useCallback(
    (name, value) => {
      form.setFieldValue(["filters", name, "value"], value);
    },
    [form]
  );

  const config = useMemo(() => {
    return columns
      .filter(({ canFilter }) => canFilter)
      .map(({ id, Header, filter, Filter, disableFilters }) => ({
        name: id,
        label: Header,
        type: filter,
        component: Filter,
        disableFilters,
      }));
  }, [columns]);

  const onFinish = useCallback(
    (values) => {
      setFilters(values.filters);
      setHasChangedDateFilter?.(true);
    },
    [setFilters, setHasChangedDateFilter]
  );

  const clearFilters = useCallback(() => {
    if (!isEqual(filters, defaultFilter)) {
      setFilters(defaultFilter);
    }
    form.setFieldValue("filters", defaultFilter);
    setHasChangedDateFilter?.(true);
  }, [setFilters, form, filters, defaultFilter, setHasChangedDateFilter]);

  useEffect(() => {
    form.setFieldValue("filters", filters);
  }, [form, filters]);

  return (
    <Form
      initialValues={{ filters }}
      form={form}
      onFinish={onFinish}
      className="table-filters d-inline-flex align-items-start justify-content-end flex-grow-1"
    >
      <div
        className={classNames(
          "align-items-start responsive-filters justify-content-end w-100",
          visible ? "d-flex mr-4" : "d-none",
        )}
      >
        <div className="d-flex flex-column gap-y-4 px-0 mr-4 w-100">
          <List name="filters">
            {(fields, { add, remove }) =>
              fields.map(({ name, key }) => (
                <Filter
                  changeFieldMethod={changeFieldMethod}
                  changeFieldValue={changeFieldValue}
                  config={config}
                  name={name}
                  key={key}
                  form={form}
                  add={() => add({}, name + 1)}
                  remove={
                    fields.length > 1
                      ? () => remove(name)
                      : undefined
                  }
                />
              ))
            }
          </List>
        </div>

        <div className="d-flex filter-buttons">
          <div className="d-block mr-2">
            <Button
              color="info"
              size="sm"
              className="btn-block"
              disabled={!hasChange || !areValid}
            >
              {t("search")}
            </Button>
          </div>

          <div className="d-block">
            <Button
              color="info"
              size="sm"
              className="btn-block text-nowrap"
              onClick={clearFilters}
              disabled={!hasChange && isEqual(list, defaultFilter)}
            >
              {t("clear-filters")}
            </Button>
          </div>
        </div>
      </div>

      {!hideFilterButton && <div className="filter-btn">
        <Toggle open={visible} toggle={toggle} />
      </div>}
    </Form>
  );
}

export default Filters;

export const systemReports = [
    {
        hasDailyHours: true,
        columns: [
            "user.level2Res.glSegment",
            "user.level2Res.description",
            "user.fullName",
            "user.employeeNumber",
            "timesheet.allAvailablePayCodes"
        ],
        id: "total-hours-by-day",
        isDefault: false,
        name: "Total Hours by day",
    },
    {
        hasDailyHours: true,
        columns: [
            "user.level2Res.glSegment",
            "user.level2Res.description",
            "user.fullName",
            "user.employeeNumber",
            "timesheet.payCode.code",
            "timesheet.allAvailablePayCodes"
        ],
        id: "detail-total-hours-by-day",
        isDefault: false,
        name: "Detailed Total Hours by day",
    },
    {
        hasDailyHours: false,
        columns: [
            'user.company.externalCompanyCode',
            'user.payGroupRes.code',
            'user.payGroupRes.description',
            "system.budgetLevel.glSegment",
            "system.budgetLevel.code",
            "system.budgetLevel.description",
            "system.actualAmount",
            "system.budgetAmount",
            "system.varianceAmount",
            'system.regularHours',
            'system.overtimeHours',
            "system.actualHours",
            "system.budgetHours",
            "system.varianceHours",
            'general.workDay',
            "general.startDate",
            "general.endDate",
        ],
        id: "actual-vs-budget",
        isDefault: false,
        name: "Actual vs Budget",
    },
    {
        hiddenDateRangeOption: true,
        columns: [
            'user.company.externalCompanyCode',
            'user.payGroupRes.code',
            "user.fullName",
            "user.employeeNumber",
            "system.date",
            'clockin.origin',
            "system.auditType",
            "system.auditDate",
            "system.auditTime",
            "system.action",
            "system.fullName",
            "system.timesheet.override.status",
            "system.timesheet.status",
            "system.overrideTimeCode",
            "timesheet.payCode.code",
            "system.overrideTimeIn",
            "timesheet.startDate",
            "system.overrideTimeOut",
            "timesheet.endDate",
            "system.overrideRate",
            "timesheet.rate",
            "system.totalHours",
            "timesheet.duration",
            "system.totalAmount",
            "timesheet.total",
            "system.allocatedLevel.description",
            "timesheet.level2.description",
            "system.allocatedLevel.glSegment",
            "timesheet.level2.glSegment",
            "system.overrideNote",
            "timesheet.note",
        ],
        columnLabels: {
            'user.company.externalCompanyCode': 'Company Code',
            'user.payGroupRes.code': 'Pay Group',
            "system.auditType": "Type",
            "system.fullName": "User",
            "timesheet.note": "Comment",
            "timesheet.duration": "Total Hours",
            "system.totalHours": "Old Total Hours",
            "timesheet.total": "Amount",
            "system.totalAmount": "Old Amount",
            "timesheet.level2.description": "Allocated Level",
            "system.allocatedLevel.description": "Old Allocated Level",
            "timesheet.level2.glSegment": "GL Segment",
            "system.allocatedLevel.glSegment": "Old GL Segment",
        },
        id: "total-audit-by-day",
        isDefault: false,
        name: "Audit Report",
    },
    {
        hasDailyHours: false,
        columns: [
            "user.payGroupRes.description",
            "user.fullName",
            "user.employeeNumber",
            "timesheet.allHoursEarningGroups",
            "system.totalHours",
            "timesheet.allAmountEarningGroups",
            "system.totalAmount",
        ],
        id: "hours-and-earning-report-by-earning-group",
        isDefault: false,
        name: "Hours and Earning report by Earning group",
    },
    {
        hasDailyHours: false,
        columns: [
            "user.payGroupRes.description",
            "user.fullName",
            "user.employeeNumber",
            "timesheet.allAvailableHoursPayCodes",
            "system.totalHours",
            "timesheet.allAmountPayCodes",
            "system.totalAmount",
        ],
        id: "hours-and-earning-report-by-pay-code",
        isDefault: false,
        name: "Hours and Earning report by Pay Code",
    },
    {
        hasDailyHours: false,
        columns: [
            "system.allocatedLevel.code",
            "system.allocatedLevel.description",
            "system.allocatedLevel.glSegment",
            "system.totalAmount",
            "system.totalOvertimeAmount",
            "system.overtimePercentage",
        ],
        id: "percentage-of-overtime-of-total-payroll-by-level",
        isDefault: false,
        name: "Percentage of Overtime of total Payroll by Level",
    },
    {
        hasDailyHours: false,
        columns: [
            "system.allocatedLevel.code",
            "system.allocatedLevel.description",
            "system.allocatedLevel.glSegment",
            "system.totalOvertimeHours",
            "system.totalOvertimeAmount",
        ],
        id: "overtime-hours-and-amount-by-level",
        isDefault: false,
        name: "Overtime Hours and Amount by Level",
    },
    {
        hasDailyHours: false,
        columns: [
          "user.employeeNumber",
          "user.fullName",
          "system.manager",
          "system.auditDate",
          "system.auditTime",
          "timesheet.date",
          "system.overrideRate",
          "system.newOverrideRate",
        ],
        id: "timesheet-rate-changes",
        isDefault: false,
        name: "Timesheet rate changes",
    },
    {
        hasDailyHours: false,
        columns: [
          "user.employeeNumber",
          "user.fullName",
          "timesheet.date",
          "timesheet.startDate",
          "timesheet.endDate",
          "timesheet.payCode.code",
          "timesheet.hourType",
          "timesheet.duration",
          "timesheet.total",
        ],
        id: "timesheet-punches",
        isDefault: false,
        name: "Timesheet punches",
    },
    {
        hasDailyHours: false,
        columns: [
            "system.date",
            "user.employeeNumber",
            "user.fullName",
            "system.scheduledAmount",
            "system.actualAmount",
            "system.varianceAmount",
            "system.scheduledHours",
            "system.actualHours",
            "system.varianceHours",
        ],
        id: "scheduled-vs-actual",
        isDefault: false,
        name: "Scheduled vs Actual",
    },
    // {
    //     columns: [
    //         "user.company.code",
    //         "user.payGroupRes.code",
    //         "user.employeeNumber",
    //         "timesheet.payCode.code",
    //         "timesheet.allAvailablePayCodes"
    //     ],
    //     columnLabels: {
    //         'user.company.code': 'Company',
    //         'user.payGroupRes.code': 'Pay Group',

    //     },
    //     id: "employee-supervisors",
    //     isDefault: false,
    //     name: "Employee Supervisor",
    // }
];
